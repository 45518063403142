"use client";

import { authAtom } from "@/lib/jotai/auth/authStore";
import { LanguageParams } from "@/lib/types/PageProps";
import { getCookiePreferences } from "@/lib/utils/cookies";
import { GoogleTagManagerEvents } from "@/lib/utils/googleTagEvents";
import { useAtomValue } from "jotai";
import { useSearchParams } from "next/navigation";
import { useEffect } from "react";

const GTMViewPpageEvent = ({ lang }: LanguageParams) => {
    const { analyticsEnabled, isLoggedIn, userInfo } = useAtomValue(authAtom);
    const searchUrlParams = useSearchParams();

    useEffect(() => {
        const gclid = searchUrlParams.get("gclid");
        if (gclid) localStorage.setItem("gclid", gclid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const cookies = getCookiePreferences();
        if (analyticsEnabled || cookies.includes("statistics"))
            GoogleTagManagerEvents.viewPage(lang, isLoggedIn ? userInfo._id : undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [analyticsEnabled]);

    return null;
};

export default GTMViewPpageEvent;
