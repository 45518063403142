"use client";

import React, { Suspense, useState } from "react";
import { LanguageParams } from "@/lib/types/PageProps";
import LanguageSelector from "../LanguageSelector";
import { useTranslation } from "@/lib/i18n/client";


const FooterLanguage = ({ lang }: LanguageParams) => {
    const { t } = useTranslation(lang, "footer");
    const [isMobileLanguageOpen, setIsMobileLanguageOpen] = useState(false);

    return (
        <Suspense>
            <div className="flex-col lg:gap-4 gap-2 flex">
                <div className="font-bold text-xl">{t("language")}</div>
                <div className="hidden lg:flex gap-3">
                    <LanguageSelector lang={lang} dark={true} showText={true} mode="up" />
                </div>
                <div className="flex lg:hidden gap-3">
                    <LanguageSelector lang={lang} dark={true} showText={true} mode="modal" onCloseMobileModal={() => setIsMobileLanguageOpen(false)} isMobileModalOpen={isMobileLanguageOpen} />
                </div>
            </div>
        </Suspense>
    );
};

export default FooterLanguage as unknown as (props: LanguageParams,) => JSX.Element;
