"use client";

import { Disclosure } from "@headlessui/react";
import Text from "../Text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/sharp-solid-svg-icons";
import { ReactNode } from "react";
import { faPlusLarge } from "@fortawesome/pro-solid-svg-icons";

type FAQItemProps = {
    children: ReactNode;
    question: string;
};

export default function FAQItem({ children, question }: FAQItemProps) {
    const renderIcon = (isOpen: boolean) => {
        const iconClasses = "h-6 w-6 ";
        return isOpen ? (
            <FontAwesomeIcon icon={faMinus} className={iconClasses} />
        ) : (
            <FontAwesomeIcon icon={faPlusLarge} className={iconClasses} />
        );
    };

    const getActiveClass = (isOpen: boolean) => {
        return isOpen ? "text-cyan-500" : "text-black";
    };

    return (
        <Disclosure as="div">
            {({ open }) => (
                <div className={`flex flex-col items-start justify-center pt-6`}>
                    <Disclosure.Button className={`flex items-center gap-2 text-left w-full ${getActiveClass(open)}`}>
                        <span className="flex h-7 items-center font-bold text-base">{renderIcon(open)}</span>
                        <Text
                            as="h6"
                            className="text-xl sm:text-xl max-sm:text-base font-bold"
                            useCustomTextSize={true}
                        >
                            {question}
                        </Text>
                    </Disclosure.Button>

                    <Disclosure.Panel as="div" className="px-4 flex">
                        <div className="pl-4 text-base sm:text-xl">{children}</div>
                    </Disclosure.Panel>
                </div>
            )}
        </Disclosure>
    );
}
