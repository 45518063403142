import { format, parse } from "date-fns";
import { ENDPOINTS } from "../constants/endpoints";
import { apiInstance, handleErrors } from "./api";
import {
    Filter,
    GetRoomCalendarRequest,
    GetRoomCategoryPriceRequest,
    GetRoomCategoryPriceResponse,
    roomDateModifier,
    RoomUnavailableDays,
    SearchApiParams,
    SearchResponse,
} from "../types/Search";
import { defaultGuests, defaultLocation, defaultSort, SEARCH_API_DATE_FORMAT } from "../constants/search";
import axios, { AxiosResponse } from "axios";

// /search/price
// params: {
//     roomCategoryId: room._id,
//     checkinDate: startDate
//       ? moment.utc(startDate).startOf('day').toDate()
//       : '',
//     checkoutDate: endDate
//       ? moment.utc(endDate).startOf('day').toDate()
//       : '',
//     numberOfAdults: adultsCount,
//     numberOfChildren: childrenCount,
//   },

// we have to fix date - apply offset so we get GMT/UTC time with correct timezone
function getUtcTimestamp(date: Date | undefined) {
    if (!date) {
        return undefined;
    }
    const timestamp = date.getTime();
    const offset = date.getTimezoneOffset() * 60 * 1000;
    return (new Date(timestamp - offset)).getTime();
}


// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace API_SEARCH {

    export async function Search(data: SearchApiParams, filterData: Filter): Promise<SearchResponse | undefined> {
        try {
            if ((data.location?.name ?? defaultLocation) != "None") {
                const { price_from, price_to, ...filter } = filterData;
                const price = price_from && price_to ? {
                    price_from: (price_from / 30 * 100).toFixed(0), // divide to get days from month as search api accepts only this
                    price_to: (price_to / 30 * 100).toFixed(0), // divide to get days from month as search api accepts only this
                } : {};
                const response = await apiInstance.get(ENDPOINTS.SEARCH,
                    {
                        params: {
                            pagination_skip: data.paginationSkip,
                            pagination_limit: data.paginationLimit,
                            numberOfAdults: data.guestCount ?? defaultGuests,
                            adultCount: data.guestCount ?? defaultGuests,
                            location: data.location?.name ?? defaultLocation,
                            address: data.location?.name ?? defaultLocation,
                            sort: data.sort ?? defaultSort,
                            between_from: getUtcTimestamp(data.startDate),
                            between_to: getUtcTimestamp(data.endDate),
                            isLongTerm: true,
                            roomCategoryId: data.roomCategoryId,
                            propertyId: data.propertyId,
                            ...price,
                            ...filter,
                        },
                    });
                return response.data as SearchResponse;
            }
            return undefined;

        } catch (error: unknown) {
            throw handleErrors(error);
        }
    }

    export async function GetRoomCategoryPrice(data: GetRoomCategoryPriceRequest): Promise<GetRoomCategoryPriceResponse[]> {
        try {
            const response = await apiInstance.get(ENDPOINTS.SEARCH_ROOM_PRICE,
                {
                    params: {
                        roomCategoryId: data.roomCategoryId,
                        checkinDate: format(data.checkinDate, SEARCH_API_DATE_FORMAT),
                        checkoutDate: format(data.checkoutDate, SEARCH_API_DATE_FORMAT),
                        numberOfAdults: data.numberOfAdults,
                        numberOfChildren: 0,
                    },
                });
            return response.data as GetRoomCategoryPriceResponse[];
        } catch (error: unknown) {
            // backend returns 404 if room is unavailable - we have to handle that
            if (axios.isAxiosError(error) && error.response?.status === 404) {
                return [];
            }
            throw handleErrors(error);
        }
    }

    export async function GetRoomCategoryCalendar(data: GetRoomCalendarRequest): Promise<RoomUnavailableDays> {
        try {
            const response = await apiInstance.get(ENDPOINTS.SEARCH_ROOM_CALENDAR,
                {
                    params: {
                        roomCategoryId: data.roomCategoryId,
                        startDate: format(new Date(data.startDate.getFullYear(), 0, 1, 0, 0, 0), SEARCH_API_DATE_FORMAT),
                        endDate: format(new Date(data.startDate.getFullYear() + 1, 0, 1, 0, 0, 0), SEARCH_API_DATE_FORMAT),
                    },
                });
            const needAnotherYear = data.startDate.getFullYear() !== data.endDate.getFullYear();
            let response2: AxiosResponse | null = null;
            if (needAnotherYear) {
                response2 = await apiInstance.get(ENDPOINTS.SEARCH_ROOM_CALENDAR,
                    {
                        params: {
                            roomCategoryId: data.roomCategoryId,
                            startDate: format(new Date(data.endDate.getFullYear(), 0, 1, 0, 0, 0), SEARCH_API_DATE_FORMAT),
                            endDate: format(new Date(data.endDate.getFullYear() + 1, 0, 1, 0, 0, 0), SEARCH_API_DATE_FORMAT),
                        },
                    });
            }

            const unavailableDaysCheckin: Date[] = [];
            const unavailableDaysCheckout: Date[] = [];
            if (response.data && (!needAnotherYear || response2?.data)) {
                if (response.data.message) {
                    throw new Error(response.data.message);
                }
                if (needAnotherYear && response2?.data.message) {
                    throw new Error(response2.data.message);
                }

                let dates: string[] = response.data;
                if (needAnotherYear) {
                    dates = dates.concat(response2?.data);
                }
                dates.forEach((roomDate) => {
                    const [day, ...rest] = roomDate.split(";");
                    const dt = parse(day, SEARCH_API_DATE_FORMAT, new Date());

                    if (rest.includes(roomDateModifier.blocked)) {
                        unavailableDaysCheckin.push(dt);
                        unavailableDaysCheckout.push(dt);
                    }

                    if (rest.includes(roomDateModifier.cantArrive)) {
                        unavailableDaysCheckin.push(dt);
                    }

                    if (rest.includes(roomDateModifier.cantDepart)) {
                        unavailableDaysCheckout.push(dt);
                    }
                });
            }

            return {
                checkin: unavailableDaysCheckin,
                checkout: unavailableDaysCheckout,
            };
        } catch (error: unknown) {
            throw handleErrors(error);
        }
    }
}
