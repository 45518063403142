import { CloudinaryImage } from "@/lib/constants/cloudinary";
import { ImageSwiperProps } from ".";
import CloudImage from "../CloudImage";
import cloudinaryLoader, { cloudinaryCoyotivLoader, simpleUrlLoader } from "@/lib/setup/cloudinaryLoader";
import { Swiper as SwiperType } from "swiper/types";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../../../assets/css/myroom-swiper.css";

// import required modules
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTailwindBreakpoint } from "@/lib/hooks/useTailwindBreakpoint";
import { breakpointIsLessThan } from "@/lib/utils/tailwind";

type SimpleImageSwiperProps = {
    showPagination?: boolean;
    showNavigation?: boolean;
    onActiveSwiperChange?: (index: number) => void;
} & ImageSwiperProps;

const SimpleImageSwiper = ({
    images,
    useOldCloudinary,
    showPagination = true,
    showNavigation = true,
    singleImage = false,
    onActiveSwiperChange,
}: SimpleImageSwiperProps) => {
    const screenSize = useTailwindBreakpoint()[1];
    const isMobile = breakpointIsLessThan(screenSize, "lg");

    const getImage = (image: CloudinaryImage, priority = false) => {
        let loader = useOldCloudinary ? cloudinaryCoyotivLoader : cloudinaryLoader;
        if (image.url.startsWith("https://")) {
            console.log("using simpleUrlLoader for image", image.url);
            loader = simpleUrlLoader;
        }

        return (
            <CloudImage
                sizes="500px"
                className="h-full object-cover object-center w-96"
                key={image?.url}
                src={image?.url ?? ""}
                alt={image?.title}
                loader={loader}
                priority={priority}
            />
        );
    };

    const handleActiveIndexChange = (swiper: SwiperType) => {
        if (onActiveSwiperChange) {
            onActiveSwiperChange(swiper.realIndex);
        }
    };

    if (singleImage) {
        return (
            <div className="overflow-clip p-0 z-[1] block h-full w-full relative">
                <div className="flex justify-center items-center h-full w-full">
                    <div className="flex h-[100%] w-152">
                        <div key={images[0].url} className={`lg:relative w-full h-full`}>
                            {getImage(images[0], true)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        // <div className="flex h-full w-full">
        <Swiper
            modules={[Pagination, Navigation]}
            onActiveIndexChange={handleActiveIndexChange}
            loop={true}
            slidesPerView={1}
            pagination={{
                enabled: showPagination,
                clickable: true,
                dynamicBullets: true,
            }}
            keyboard={true}
            grabCursor={true}
            navigation={showNavigation && !isMobile}
            className="h-full simple-image-swiper-navigation-behavior"
            lazyPreloadPrevNext={2}
        >
            {images.map((image, index) => {
                return (
                    <SwiperSlide key={index}>
                        <div className="flex justify-center items-center h-full w-full">
                            <div className="flex h-[100%] w-152">
                                <div key={image?.url} className={`lg:relative w-full h-full`}>
                                    {getImage(image, index == 0)}
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                );
            })}
        </Swiper>
        // </div>
    );
};

export default SimpleImageSwiper;
