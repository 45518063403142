"use client";

import { CLOUDINARY_FOLDER } from "@/lib/constants/cloudinary";
import Text from "../Text";
import YouTube from "../YouTube/YouTube";

type Props = {
    description: string;
    src: string;
    className?: string;
    showThumbnail?: boolean;
    customThumbnail?: string;
};

export function FAQContent({
    description = "",
    src,
    className = "",
    showThumbnail = true,
    customThumbnail = "",
}: Props) {
    const thumbnail =
        customThumbnail !== null && customThumbnail !== ""
            ? `${CLOUDINARY_FOLDER}/${customThumbnail}`
            : "";
    return (
        <div
            className={`flex flex-col gap-6 lg:py-20 py-12 w-full justify-between items-center ${className}`}
        >
            <Text
                as="p"
                className="sm:text-xl text-base leading-6 font-medium lg:w-3/4 w-full"
            >
                {description}
            </Text>
            <div className="w-full aspect-video relative bg-black rounded-2xl">
                <YouTube source={src} customThumbnail={thumbnail} showThumbnail={showThumbnail}/>
            </div>
        </div>
    );
}

export function FAQContentLeft({ description = "", src, customThumbnail, showThumbnail = true }: Props) {
    return FAQContent({
        description,
        src,
        showThumbnail,
        customThumbnail,
        className: "w-full lg:flex-row flex-col-reverse",
    });
}

export function FAQContentRight({ description = "", src, customThumbnail, showThumbnail = true }: Props) {
    return FAQContent({
        description,
        src,
        showThumbnail,
        customThumbnail,
        className: "w-full lg:flex-row-reverse flex-col-reverse",
    });
}
