import Text from "@/lib/components/Text";
import React from "react";

type ContentHeaderProps = {
    title: string;
    description?: string;
};

const ContentHeader = ({ title, description = "" }: ContentHeaderProps) => {
    return (
        <div className="py-6 flex flex-col gap-3 justify-center text-black w-full">
            <Text as="h6" className="text-3xl font-bold sm:text-3xl max-sm:text-1.5xl" useCustomTextSize={true}>
                {title}
            </Text>
            <Text as="p" className="font-medium text-base sm:text-xl ">
                {description}
            </Text>
        </div>
    );
};

export default ContentHeader;
