import React, { ReactNode } from "react";
import Container from "../Containers/Container";


type Props = {
    children: ReactNode;
    whiteBackground?: boolean;
    noXMargin?: boolean;
};

const HomeContainer = ({ children, whiteBackground = false, noXMargin = false }: Props) => {
    return (
        <Container noXMargin={noXMargin} noYMargin className={`py-4 sm:py-6 lg:py-20 xl:py-20 ${whiteBackground ? "bg-white" : ""}`}>{children}</Container>
    );
};

export default HomeContainer;
