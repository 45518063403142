import { useTranslation } from "@/lib/i18n/client";
import { LanguageParams } from "@/lib/types/PageProps";
import { faBed, faCheck, faDrawSquare, faTimes, faBath , faUser, faUtensils } from "@fortawesome/pro-regular-svg-icons"; // import the new icons
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import Text from "../Text";

export type RoomInfoProps = {
    numGuests: number;
    numBedrooms: number;
    numBeds: number;
    numBaths: number;
    hasKitchen: boolean; // updated property
    isMobile?: boolean;
} & LanguageParams;

type RoomFeature = {
    title: React.ReactNode;
    className?: string;
    isMobile?: boolean;
} & Pick<FontAwesomeIconProps, "icon">;

const RoomFeature = ({ title, icon, className, isMobile = false }: RoomFeature) => {
    return (
        <Text as="span" className={`${isMobile ? "text-s" : "text-xs"} text-black ${className}`}>
            <FontAwesomeIcon icon={icon} className="pr-1" />
            {title}
        </Text>
    );
};

const RoomInfo = ({ numGuests, numBedrooms, numBeds, numBaths, hasKitchen, isMobile = false, lang }: RoomInfoProps) => {
    const { t } = useTranslation(lang, "search");

    return (
        <div className={`h-full flex flex-row ${isMobile ? "gap-4" : "justify-between"} items-center`}>
            <RoomFeature icon={faUser} title={isMobile ? numGuests.toString() : t("room.guests", { count: numGuests })}
                         isMobile={isMobile} />
            <RoomFeature icon={faDrawSquare}
                         title={isMobile ? numBedrooms.toString() : t("room.bedrooms", { count: numBedrooms })}
                         isMobile={isMobile} />
            <RoomFeature icon={faBed} title={isMobile ? numBeds.toString() : t("room.beds", { count: numBeds })}
                         isMobile={isMobile} />
            {/* Conditionally render the kitchen feature only if hasKitchen is true */}
            {hasKitchen && (
                <RoomFeature
                    icon={faUtensils}
                    title={
                        isMobile
                            ? (hasKitchen ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />)
                            : t("room.kitchen", { value: hasKitchen ? "Available" : "Not Available" })
                    }
                    isMobile={isMobile}
                />
            )}
            {!hasKitchen && (
            <RoomFeature icon={faBath} title={isMobile ? numBaths.toString() : t("room.baths", { count: numBaths })} isMobile={isMobile} />
            )}
        </div>
    );
};

export default RoomInfo;
