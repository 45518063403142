"use client";

import ContentHeader from "../Headers/ContentHeader";
import RoomSlider from "../RoomSlider";
import { getDefaultSearchParams } from "@/lib/utils/search";
import { LanguageParams } from "@/lib/types/PageProps";
import { SimpleSearchUrlProps } from "@/lib/types/Search";
import HomeContainer from "./HomeContainer";
import { useTranslation } from "@/lib/i18n/client";
import { Suspense } from "react";

type Props = {
    searchParams: SimpleSearchUrlProps;
    whiteBackground?: boolean;
} & LanguageParams;

const FeaturedHomes = ({ searchParams, whiteBackground = false, lang }: Props) => {
    const { t } = useTranslation(lang, "home");
    const locationParams: SimpleSearchUrlProps = { ...getDefaultSearchParams(), ...searchParams };

    return (
        <Suspense>
            <HomeContainer whiteBackground={whiteBackground}>
                <ContentHeader
                    title={t("sections.featuredHomes.title")}
                    description={t("sections.featuredHomes.subtitle") || undefined}
                />
                <RoomSlider isFeatured={true} params={locationParams} style="home" limit={10} />
            </HomeContainer>
        </Suspense>
    );
};

export default FeaturedHomes;
