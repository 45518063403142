"use client";
import { useState } from "react";
import Image from "next/image";
import cloudinaryLoader from "@/lib/setup/cloudinaryLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay } from "@fortawesome/sharp-solid-svg-icons";

export type YouTubeProps = {
    source: string;
    showThumbnail? : boolean;
    customThumbnail?: string;
    thumbnailQuality?: "default" | "hqdefault" | "mqdefault" | "sddefault";
    className?: string;
};

type LoaderProps = {
    src: string;
};

function YouTube({
    source,
    showThumbnail = true,
    thumbnailQuality = "hqdefault",
    customThumbnail,
    className,
}: YouTubeProps) {
    const [isShowThumbnail, setIsShowThumbnail] = useState(showThumbnail);

    const youTubeThumbnailLoader = ({ src }: LoaderProps) => {
        return `https://img.youtube.com/vi/${src}/${thumbnailQuality}.jpg`;
    };

    const getVideoId = (url: string): string => {
        const regex =
            /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|watch\?v=|v\/)|youtu\.be\/)([\w-]{11})(?:\S+)?$/;
        const match = regex.exec(url);

        if (match?.at(1)) {
            return match[1];
        }

        return "";
    };

    const thumbnailLoader =
        customThumbnail === undefined || customThumbnail === ""
            ? youTubeThumbnailLoader
            : cloudinaryLoader;

    const videoId = getVideoId(source);
    const src =
        customThumbnail === undefined || customThumbnail === ""
            ? videoId
            : customThumbnail;

    const playVideo = () => {
        setIsShowThumbnail(false);
    };

    const youtubeIframe = <iframe
      style={{
        "width": "100%",
        "height": "100%",
        "border": "0px",
      }}
      allowFullScreen
      allow={"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; "}
      src={`https://www.youtube.com/embed/${videoId}?autoplay=${showThumbnail ? 1 : 0}&mute=0&controls=${showThumbnail ? 0 : 1}&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1`}
    />;

    return (
        <div
            className={`flex items-center justify-center w-full h-full rounded-2xl overflow-hidden cursor-pointer ${className}`}
        >
            {!isShowThumbnail ? youtubeIframe :
                <div className="relative w-full h-full z-0 group" onClick={playVideo}>
                    <div
                        className="absolute inset-0 bg-white opacity-0 transition-opacity duration-300 group-hover:opacity-10 z-10"
                    />
                    <div
                        className="absolute inset-0 flex items-center justify-center w-full h-full grow"
                    >
                        <FontAwesomeIcon
                            icon={faCirclePlay}
                            className="text-white text-5xl group-hover:text-primary-300 z-10"
                        />
                        <Image
                            loader={thumbnailLoader}
                            src={src}
                            alt="Thumbnail"
                            fill
                            style={{ objectFit: "cover" }}
                            className="z-0"
                        />
                    </div>
                </div>
            }
        </div>
    );
}

export default YouTube as unknown as ({
    source,
    thumbnailQuality,
    customThumbnail,
    className,
}: YouTubeProps) => JSX.Element;
