"use client";

import { useLanguage } from "@/lib/hooks/useLanguage";
import Room, { RoomProps } from "@/lib/components/Room";
import { SCREEN_BREAKPOINTS } from "@/lib/constants/tailwind";
import { useEffect, useRef, useState } from "react";
import { convertToListing, extractFilterUrlParameters, extractSimpleSearchUrlParameters } from "../utils/search";
import { useQueryParams } from "../hooks/useQueryParams";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft, faChevronCircleRight } from "@fortawesome/pro-solid-svg-icons";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../assets/css/slider-room.css";
import { API_SEARCH } from "../services/searchService";
import { SimpleSearchUrlProps } from "../types/Search";
import { breakpointIsLessThan } from "../utils/tailwind";
import { useTailwindBreakpoint } from "../hooks/useTailwindBreakpoint";

type Props = {
    isFeatured?: boolean;
    params: SimpleSearchUrlProps;
    style: "room-details" | "home";
    limit?: number;
    className?: string;
};

const RoomSlider = ({ params, style, className = "", limit = 10, isFeatured = false }: Props) => {
    const lang = useLanguage();
    const [rooms, setRooms] = useState<RoomProps[]>([]);
    const searchUrlParams = useQueryParams();
    const refSlider = useRef<Slider | null>(null);
    const screenSize = useTailwindBreakpoint()[1];
    const isMobile = breakpointIsLessThan(screenSize, "lg");

    const getNearby = async () => {
        const result = await API_SEARCH.Search(
            {
                ...extractSimpleSearchUrlParameters(params),
                paginationSkip: 0,
                paginationLimit: 1000, // this is useless with current state of backend
                sort: "price_asc",
                priceFrom: 0,
                language: lang,
            },
            extractFilterUrlParameters(searchUrlParams),
        );

        const roomData: RoomProps[] =
            result?.results.map((resultRoom) => convertToListing(resultRoom, lang)).slice(0, limit) ?? [];
        setRooms(roomData);
    };

    useEffect(() => {
        if (params && Object.keys(params).length > 0) {
            getNearby();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.location, params.endDate, params.guestCount, params.language, params.startDate]);

    let prev = (
        <>
            <div
                className="hidden group-hover:block absolute z-10 top-1/3 -left-4 text-black hover:text-primary-500 cursor-pointer"
                onClick={() => refSlider.current?.slickPrev()}
            >
                <FontAwesomeIcon
                    className="text-white hover:text-black drop-shadow-xl"
                    size="2x"
                    icon={faChevronCircleLeft}
                />
            </div>
        </>
    );
    let next = (
        <>
            <div
                className="hidden group-hover:block absolute top-1/3 -right-4 text-black hover:text-primary-500 cursor-pointer"
                onClick={() => refSlider.current?.slickNext()}
            >
                <FontAwesomeIcon
                    className="text-white hover:text-black drop-shadow-md"
                    size="2x"
                    icon={faChevronCircleRight}
                />
            </div>
        </>
    );
    if (style === "room-details") {
        prev = (
            <>
                <div
                    className="block absolute top-1/3 left-0 text-black hover:text-primary-500 cursor-pointer"
                    onClick={() => refSlider.current?.slickPrev()}
                >
                    <FontAwesomeIcon size="3x" icon={faChevronCircleLeft} />
                </div>
            </>
        );
        next = (
            <>
                <div
                    className="block absolute top-1/3 right-0 text-black hover:text-primary-500 cursor-pointer"
                    onClick={() => refSlider.current?.slickNext()}
                >
                    <FontAwesomeIcon size="3x" icon={faChevronCircleRight} />
                </div>
            </>
        );
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: style === "home" ? 3 : 3,
        slidesToScroll: 1,
        swipeToSlide: true,
        prevArrow: prev,
        nextArrow: next,
        arrows: !isMobile,
        responsive: [
            {
                breakpoint: SCREEN_BREAKPOINTS.xl,
                settings: {
                    slidesToShow: style === "home" ? 3 : 3,
                },
            },
            {
                breakpoint: SCREEN_BREAKPOINTS.lg,
                settings: {
                    slidesToShow: style === "home" ? 3 : 2,
                },
            },
            {
                breakpoint: SCREEN_BREAKPOINTS.md,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: SCREEN_BREAKPOINTS.sm,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <div
            className={`${
                style === "home" ? "slick-partners home-slider" : "room-details-slider"
            } ${className} m-auto group`}
        >
            <Slider {...settings} ref={refSlider}>
                {rooms.map((room) => {
                    return (
                        <div key={room.roomId}>
                            <Room isFeaturedRoom={isFeatured} {...room} disableImageSwipe={true} />
                        </div>
                    );
                })}
            </Slider>
        </div>
    );
};

export default RoomSlider;
