"use client";

import { CLOUDINARY_FOLDER } from "@/lib/constants/cloudinary";
import CloudImage from "../CloudImage";

type Props = {
    src: string;
    alt: string;
    className?: string;
}

export function MDXImage({ src, alt, className = "" }: Props) {
    return (
        <div className={`relative h-72 rounded-md md:rounded-none overflow-hidden ${className}`}>
            <CloudImage src={`${CLOUDINARY_FOLDER}/${src}`} alt={alt} className="object-cover" />
        </div>
    );
}

export function MDXImageRight({ src, alt }: Props) {
    return MDXImage({ src, alt, className: "md:w-1/3 md:float-right md:ml-6" });
}

export function MDXImageLeft({ src, alt }: Props) {
    return MDXImage({ src, alt, className: "md:w-1/3 md:float-left md:mr-6" });
}
