import { useTranslation } from "@/lib/i18n/client";
import { LanguageParams } from "@/lib/types/PageProps";

type Props = {
    title: string;
    className: string;
    fontSize?: "xs" | "xxs";
}
const Badge = ({ title, fontSize = "xxs", className }: Props) => {
    return (
        <span className={`inline-flex items-center rounded-md mr-2 px-2 py-1.5 text-${fontSize} font-medium text-white uppercase ${className}`}>
            {title}
        </span>
    );
};

export type BadgesProps = {
    isInstantBooking: boolean;
    isFeatured: boolean;
    isSuperHost: boolean;
    fontSize?: "xs" | "xxs";
    padding?: "normal" | "large"
};

const Badges = ({ isInstantBooking, isFeatured, isSuperHost, fontSize = "xxs", lang, padding = "normal" }: BadgesProps & LanguageParams) => {
    const { t } = useTranslation(lang, "search");

    return (
        <>
            {isInstantBooking && <Badge title={t("room.badges.instantBooking")} className={`bg-primary-500 ${padding === "large" && "py-2 px-4 lg:py-0.5 lg:px-1.5"}`} fontSize={fontSize} />}
            {isFeatured && <Badge title={t("room.badges.featured")} className={`bg-cyan-500 ${padding === "large" && "py-2 px-4 lg:py-0.5 lg:px-1.5"}`} fontSize={fontSize} />}
            {isSuperHost && <Badge title={t("room.badges.superHost")} className={`bg-green-500 ${padding === "large" && "py-2 px-4 lg:py-0.5 lg:px-1.5"}`} fontSize={fontSize} />}
        </>
    );
};

export default Badges;
