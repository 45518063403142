"use client";

import { useState } from "react";
import SearchBar from "../../SearchBar";
import Input from "../../Input";
import { useTranslation } from "@/lib/i18n/client";
import { LanguageParams } from "@/lib/types/PageProps";

type Props = {
    location?: string;
} & LanguageParams;

const SearchBarHero = ({ lang, location }: Props) => {
    const { t } = useTranslation(lang, "search");
    const [isMobileOpen, setIsMobileOpen] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const openMobileSearch = () => {
        setIsMobileOpen(true);
    };

    return (
        <>
            <div className="w-full px-6 xl:hidden sm:w-1/2">
                <Input placeholder={t("where-to-go") ?? ""} className="w-full" onClick={openMobileSearch} readOnly />
            </div>
            <SearchBar
                isOnResultsPage={false}
                defaultProps={{ location: { name: location ?? "" } }}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                className="xl:pb-2 w-full xl:w-2/3"
                onCloseMobileModal={() => setIsMobileOpen(false)}
                isMobileModalOpen={isMobileOpen}
            />
        </>
    );
};

export default SearchBarHero;
