import React, { ReactNode } from "react";


type Props = {
    children: ReactNode;
    className?: string;
    maxWidth?: number;
    noXMargin?: boolean;
    noYMargin?: boolean;
};

const Container = ({ children, className, maxWidth = 7, noXMargin = false, noYMargin = false }: Props) => {
    return (
        <div className={`w-full ${className ?? ""}`}>
            <div className={`w-full mx-auto max-w-${maxWidth}xl ${!noXMargin ? "px-6 lg:px-12 xl:px-16" : ""} ${!noYMargin ? "py-20 sm:py-20 lg:py-44 xl:py-44" : ""}`}>
                {children}
            </div>
        </div>
    );
};

export default Container;
